import Bugsnag from "@bugsnag/js";
import { DataPipeline } from "DataPipeline/DataPipeline";
import { DataPipelineErrorEventPayload } from "DataPipeline/DataPipelineEventPayload";
import { EventType } from "EventObserver/EventPublisher";
import { type EventSubscriber } from "EventObserver/EventSubscriber";

type ErrorEventPayload = {
  source: string;
  message: string;
  context?: any;
};

class DataPipelineErrorEventSubscriber implements EventSubscriber {
  private consoleLogErrors = true; // TODO: refactor to logging framework or create console subscriber
  private notifyBugSnag = true; // TODO: clean up BugSnag usage after testing
  private supportedEvents: EventType[] = [
    EventType.ErrorContentService,
    EventType.ErrorProofOfPlay,
  ];

  public async receiveEvent(eventType: EventType, payload: ErrorEventPayload): Promise<boolean> {
    if (!this.supportedEvents.includes(eventType)) {
      return false;
    }
    const { source, message, context } = payload;
    const stringifiedContext = await this.stringifyContext(context);
    const errorPayload = new DataPipelineErrorEventPayload(source, message, stringifiedContext);
    DataPipeline.record(errorPayload);
    if (this.consoleLogErrors) {
      console.error(`[${source}] ${message}`, context);
    }
    if (this.notifyBugSnag) {
      Bugsnag.notify(new Error(`[${source}] ${message}: ${stringifiedContext}`));
    }
    return true;
  }

  public async stringifyContext(context: any): Promise<string> {
    switch (true) {
      case context instanceof Error:
        return JSON.stringify({
          name: context.name,
          message: context.message,
          cause: context.cause,
        });
      case context instanceof Response:
        return JSON.stringify({
          status: context.status,
          statusText: context.statusText,
          type: context.type,
          url: context.url,
          headers: Object.fromEntries(context.headers),
          body: context.bodyUsed ? "Body already used" : await context.text(),
        });
      case context === null || context === undefined || context === "":
        return "";
      default:
        return JSON.stringify(context);
    }
  }
}

export { DataPipelineErrorEventSubscriber };
export type { ErrorEventPayload };
