import Axios from "axios";

export class DataPipelineApi {
  async uploadEvents(events: Array<any>) {
    const url = process.env.REACT_APP_DATA_PIPELINE_UPLOAD_URL;
    if (!url) throw new Error("Undefined env: REACT_APP_DATA_PIPELINE_UPLOAD_URL");
    if (process.env.NODE_ENV === "development")
      console.log("FAKE (not in prod) uploading events to", url, events);
    else {
      await Axios.post(url, events, {
        headers: {
          "content-type": "application/json",
        },
      });
    }
  }
}
