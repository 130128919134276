import Axios from "axios";

export class Elev8Api {
  async sendWindowSize(displayId: string, windowWidth: number, windowHeight: number) {
    const payload = {
      content_area_width: windowWidth,
      content_area_height: windowHeight,
    };
    await this.putMessage(displayId, payload);
  }

  private async putMessage(displayId: string, payload: object) {
    const baseUrl = process.env.REACT_APP_CONTENT_API_BASE_URL;
    if (!baseUrl) throw new Error("Undefined env: REACT_APP_CONTENT_API_BASE_URL");
    const url = `${baseUrl}/api/screens/${displayId}`;
    await Axios.put(url, payload, {
      headers: {
        "content-type": "application/json",
      },
    });
  }
}
