// You must build (npm run build/ci:checks/test) at least once to generate this file
import buildMeta from "../buildMeta.json";

interface BuildMeta {
  id: string;
}

export const intervalPeriod = Number(process.env.REACT_APP_UPDATE_REFRESH_INTERVAL ?? 60000);
const buildId: string = (buildMeta as BuildMeta).id;

function forceRefresh() {
  window.location.reload();
}

async function checkUpdateStatus() {
  const fetchUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:8080/update-status"
      : "/update-status";
  console.log(`[UPDATE-CHECKER] checking for updates at ${fetchUrl}. Build ID: ${buildId}`);
  try {
    const response = await fetch(fetchUrl);
    if (!response.ok) {
      console.error("[UPDATE-CHECKER] response not ok", response);
      throw new Error("response not ok");
    }
    const result: BuildMeta = await response.json();
    console.log("[UPDATE-CHECKER] result", result);

    if (result.id !== buildId) {
      console.warn("[UPDATE-CHECKER] new build detected! Refreshing to trigger fetch.");
      forceRefresh();
    } else {
      console.log("[UPDATE-CHECKER] no new build detected");
    }
  } catch (error) {
    console.error("[UPDATE-CHECKER] error", error);
  }
}

export function updateChecker() {
  console.log(`[UPDATE-CHECKER] starting update checker service with interval ${intervalPeriod}ms`);
  const interval = setInterval(async () => await checkUpdateStatus(), intervalPeriod);
  return () => clearInterval(interval);
}
